import { HeroBanner } from 'components/HeroBanner';
import { HeroBannerMasterbrand } from 'components/HeroBannerMasterbrand';
import { ModalBox } from 'components/ModalBox';
import NextImage from 'components/NextImage';
import { ImagesService } from 'service/images/imagesService';
import { ISliderCarousel } from '..';
import { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { TrackingService } from 'service/tracking/trackingService';

export const SlideType = ({
  sliderType,
  item,
  index,
  pageType,
  watchVideoLabel,
  isRecipeBanner = false,
  videoData = '',
  description,
  thumbnailUrls,
  embedUrl,
  uploadDate,
  duration,
  interactionCount,
}: ISliderCarousel & ISharedTemplateProps) => {
  if (!item) {
    return null;
  }

  const trackingEvent =
    pageType === 'homepage'
      ? {
          clickHeroBannerCTA: TrackingService.homePageHeaderCTA,
        }
      : undefined;
  const imageStyle = 'sm:h-full sm:w-auto md:w-full md:h-auto absolute';
  const bannerItem = item;
  const image = bannerItem?.bannerImage?.[0];
  const mobileImage = bannerItem?.mobileImage?.[0] || bannerItem?.mobileImage;
  const imageSrc = item;
  const { bannerVideoUrl } = item;
  switch (sliderType) {
    case 'video': {
      return (
        <div className="relative h-full" data-test="video-slide">
          <span
            data-test="video-play"
            data-print="hide"
            className="sm:inset-y-1/3 md:inset-y-1/3.2 sm:inset-x-1/3.2 md:inset-x-1/3.5 absolute cursor-pointer sm:w-20 sm:h-20 md:w-108 md:h-108 z-10"
          >
            <ModalBox
              data-test="video-modal-box"
              videoID={videoData}
              description={description || ''}
              thumbnailUrls={thumbnailUrls ?? []}
              embedUrl={embedUrl || ''}
              uploadDate={uploadDate || ''}
              duration={duration || ''}
              interactionCount={interactionCount || 0}
            />
          </span>
          <NextImage
            sources={ImagesService.getPictureSources(imageSrc)}
            quality={75}
            alt="hero image"
            className={imageStyle}
            priority={index === 0}
          />
        </div>
      );
    }
    case 'banner': {
      if (bannerItem.__typename === 'HeroBannerMasterbrand') {
        return (
          <HeroBannerMasterbrand
            data-test="hero-banner"
            image={image}
            link={bannerItem.bannerSize === 'Tall' ? bannerItem.link : undefined}
            title={bannerItem.bannerTitle}
            subTitle={bannerItem.bannerSubtitle}
            description={bannerItem.bannerDescriptionText}
            textColor={bannerItem.textColor}
            buttonColor={bannerItem.buttonColor}
            alignment={bannerItem.alignment}
            verticalAlignment={bannerItem.verticalAlignment}
            verticalAlignmentMobile={bannerItem.verticalAlignmentMobile}
            bannerSize={bannerItem.bannerSize}
            superText={bannerItem.superText}
            mobileImage={mobileImage || ''}
            video={bannerVideoUrl}
            watchVideoLabel={watchVideoLabel}
            isRecipeBanner={isRecipeBanner}
            trackingEvents={trackingEvent}
            isOverlay={bannerItem.showOverlay}
            priority={index === 0}
          />
        );
      }
      return (
        <HeroBanner
          data-test="hero-banner"
          image={image}
          link={bannerItem.bannerSize === 'Tall' ? bannerItem.link : undefined}
          title={bannerItem.bannerTitle}
          description={bannerItem.bannerDescriptionText}
          textColor={bannerItem.textColor}
          buttonColor={bannerItem.buttonColor}
          alignment={bannerItem.bannerSize === 'Tall' ? bannerItem.alignment : undefined}
          verticalAlignment={bannerItem.verticalAlignment}
          verticalAlignmentMobile={bannerItem.verticalAlignmentMobile}
          bannerSize={bannerItem.bannerSize}
          superText={bannerItem.superText}
          mobileImage={mobileImage || ''}
          video={bannerVideoUrl}
          watchVideoLabel={watchVideoLabel}
          isRecipeBanner={isRecipeBanner}
          trackingEvents={trackingEvent}
          priority={index === 0}
        />
      );
    }
    case 'image': {
      return <NextImage data-test="carousel-image" src={imageSrc} alt="" className={imageStyle} />;
    }
    default: {
      return (
        <NextImage
          data-test="carousel-image-default"
          sources={ImagesService.getPictureSources(imageSrc)}
          alt=""
          className={imageStyle}
        />
      );
    }
  }
};
