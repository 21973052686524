import React from 'react';
import NextImage from 'components/NextImage';

export const SliderImageItem: React.FC<{ item: string; index: number }> = ({ item, index }) => {
  return (
    <div key={`image-carousel-cards-${index}--image`} className="min-w-full mr-0">
      <div className="w-full">
        <NextImage
          key={`slider-image-${item}`}
          data-test="image"
          itemProp="image"
          src={item}
          alt="34ertyuhjkl"
          priority={index === 0}
          className="w-full"
        />
      </div>
    </div>
  );
};
