import React from 'react';

const SliderCarouselItem: React.FC<{ item: any; children: React.ReactNode }> = ({
  item,
  children,
}) => {
  return (
    <div className="mr-0 min-w-full">
      <div
        className="h-heroCarouselInnerTallMob md:h-heroInner relative"
        key={`slider-carousel-${item}`}
        data-test="slider-carousel-inner"
      >
        {children}
      </div>
    </div>
  );
};

export default SliderCarouselItem;
