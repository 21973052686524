import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { ISliderVanillaProps } from './types';
import { CarouselSlideLeft, CarouselSlideRight, CarouselWrapper } from './styles';
import { Swiper as SwiperType } from 'swiper';

// Utility function for debouncing
const debounce = (func: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const Slider = ({
  slidesPerView = 3.5,
  slidesPerGroup = 3,
  isFullWidth = false,
  isStaticArrows = false,
  enableDots = true,
  enableMobileDots = false,
  className = '',
  isOverflowHidden = true,
  children,
}: ISliderVanillaProps) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [swiperSettings, setSwiperSettings] = useState({
    slidesPerGroup: slidesPerGroup,
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = debounce(() => {
      const newIsMobile = window.innerWidth < 1024;
      setIsMobile(newIsMobile);

      setSwiperSettings({
        slidesPerGroup: newIsMobile ? 1 : slidesPerGroup,
      });

      if (swiperRef.current) {
        swiperRef.current.update();
      }
    }, 500);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [slidesPerView, slidesPerGroup]);

  const handlePrevArrowClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextArrowClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const shouldBeFullWidth = isFullWidth && (swiperRef.current?.slides.length ?? 0) > 1;
  const slideCount = React.Children.count(children);

  return (
    <CarouselWrapper
      data-test="carousel-wrapper"
      className={className}
      $isOverflowHidden={isOverflowHidden}
      style={{ width: shouldBeFullWidth ? '100%' : undefined }}
    >
      {!isStaticArrows && slideCount > 1 && <CarouselSlideLeft onClick={handlePrevArrowClick} />}
      <Swiper
        modules={[Pagination]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={20}
        slidesPerView={slidesPerView}
        slidesPerGroup={swiperSettings.slidesPerGroup}
        loop={false}
        pagination={{
          el: '.swiper-pagination',
          clickable: enableDots,
          enabled: isMobile ? enableMobileDots : true,
        }}
        speed={600}
        breakpoints={{
          320: { slidesPerView: isFullWidth ? 1 : 1.5, spaceBetween: 24, slidesPerGroup: 1 },
          640: { slidesPerView: isFullWidth ? 1 : 2.5, spaceBetween: 20, slidesPerGroup: 1 },
          1024: { slidesPerView: slidesPerView, spaceBetween: 20 },
        }}
        className={className}
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide
            key={index}
            className="pb-16"
            style={{ width: shouldBeFullWidth ? '100%' : undefined }}
          >
            {child}
          </SwiperSlide>
        ))}
        <div className="pb-16 md:pb-0 swiper-pagination"></div>
      </Swiper>
      {!isStaticArrows && slideCount > 1 && <CarouselSlideRight onClick={handleNextArrowClick} />}
    </CarouselWrapper>
  );
};
