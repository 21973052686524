import React from 'react';
import { CarouselInner } from '../styles';
import SliderCarouselItem from './SliderCarouselItem';
import { SliderImageItem } from './SliderImageItem';
import { SliderTextItem } from './SliderTextItem';
import { SlideType } from './SliderType';
import { ISliderCarousel } from '..';
import { Slider } from '@/components/SliderVanilla';

interface DynamicSliderProps extends ISliderCarousel {
  data: any[];
  bannerSizeFlag: string;
}

export const DynamicSlider: React.FC<DynamicSliderProps> = ({
  data,
  bannerSizeFlag = 'Tall',
  type,
  className,
  recipeTipsTitle,
  isForBanners,
  video,
}) => {
  return (
    <>
      {type === 'text' && (
        <Slider
          slidesPerView={1}
          slidesPerGroup={1}
          data-test="slider"
          className={className}
          isFullWidth={true}
          enableMobileDots={true}
        >
          {data?.map((item: any, index: number) => (
            <SliderTextItem
              key={`overlay-values-${index}`}
              item={item}
              index={index}
              recipeTipsTitle={recipeTipsTitle || 'Recipe Tips'}
            />
          ))}
        </Slider>
      )}

      {type === 'image' && (
        <Slider
          slidesPerView={1}
          slidesPerGroup={1}
          data-test="slider"
          className={className}
          isFullWidth={true}
          enableMobileDots={true}
        >
          {data?.map((item: any, index: number) => (
            <SliderImageItem
              key={`image-carousel-cards-${index}--image`}
              item={item as string}
              index={index}
            />
          ))}
        </Slider>
      )}

      {type === 'carousel' && (
        <Slider
          slidesPerView={1}
          slidesPerGroup={1}
          data-test="slider"
          className={className}
          isFullWidth={true}
          enableMobileDots={true}
        >
          {data?.map((item: any, index: number) =>
            isForBanners ? (
              <div className="mr-0 min-w-full" key={`image-carousel-cards-${index}--carousel`}>
                <CarouselInner
                  key={`slider-carousel-${item}`}
                  data-test="slider-carousel-inner"
                  $bannerSizeFlag={bannerSizeFlag}
                >
                  <SlideType sliderType="banner" item={item} index={index} />
                </CarouselInner>
              </div>
            ) : data.length >= 1 && video && index === 0 ? (
              <SliderCarouselItem key={`image-carousel-cards-${index}--video`} item={item}>
                <SlideType sliderType="video" item={item} index={index} />
              </SliderCarouselItem>
            ) : (
              <SliderCarouselItem key={`image-carousel-cards-${index}--default`} item={item}>
                <SlideType sliderType="image" item={item} index={index} />
              </SliderCarouselItem>
            ),
          )}
        </Slider>
      )}
    </>
  );
};
