import React from 'react';

const SliderWrapper = ({
  type,
  $customColor,
  $bannerSizeFlag,
  children,
  className,
}: {
  type: string;
  $customColor?: string;
  $bannerSizeFlag?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const getBackgroundStyles = () => {
    return type === 'carousel' && $customColor ? `bg-${$customColor}` : '';
  };

  const getCarouselStyles = () => {
    return type === 'carousel'
      ? $bannerSizeFlag === 'Tall'
        ? 'bottom-5vh md:bottom-12vh lg:bottom-30px'
        : 'bottom-75px lg:bottom-30px'
      : '';
  };

  const getTextStyles = () => {
    return type === 'text' ? 'bottom-70px md:bottom-50px' : '';
  };

  return (
    <div
      className={`textSlider w-75 ${getBackgroundStyles()} ${getCarouselStyles()} ${getTextStyles()} ${className}`}
    >
      {children}
    </div>
  );
};

export default SliderWrapper;
