import React from 'react';
import { Title, Text as Paragraph } from 'components/Typography';

export const SliderTextItem: React.FC<{ item: any; index: number; recipeTipsTitle: string }> = ({
  item,
  index,
  recipeTipsTitle,
}) => {
  return (
    <div
      key={`overlay-values-${index}`}
      className="flex justify-center items-center min-w-full mr-0"
    >
      <div
        data-test="slider-div"
        key={`slider-${item.id}`}
        className="pb-10 pt-10 mb-5 w-3/4 text-center md:text-left textSlider"
      >
        <Title className="py-5" data-test="tips-title" tag="div" type="md">
          {recipeTipsTitle || 'Recipe Tips'}
        </Title>
        <Paragraph data-test="text" tag="div" type="lg">
          <span dangerouslySetInnerHTML={{ __html: item.Text }} />
        </Paragraph>
      </div>
    </div>
  );
};
