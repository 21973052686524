import { ITips } from './../RecipeTips';

import type { ISharedTemplateProps } from './../../templates/TemplateMapper/types';
import { DynamicSlider } from './components/DynamicSlider';
import SliderWrapper from './components/SliderWrapper';

export enum BannerHeight {
  Short = 'Short',
  Tall = 'Tall',
}

interface BannerSize {
  bannerSize?: BannerHeight | null;
}

interface BannerMasterbrands extends BannerSize {
  showOverlay?: boolean;
}

export interface ISliderCarousel {
  setDark?: (bool: boolean) => void;
  isForBanners?: boolean;
  type?: string;
  isRecipeBanner?: boolean;
  recipeTipsTitle?: string;
  dotsOnTop?: number;
  isOverflowHidden?: boolean;
  watchVideoLabel?: string;
  customStyle?: string;
  videoData?: string;
  video?: string;
  tips?: ITips[];
  bannerMasterbrands?: BannerMasterbrands[];
  banners?: BannerSize[];
  sliderType?: string;
  item?: any;
  index?: number;
  thumbnailUrls?: string[];
  embedUrl?: string;
  uploadDate?: string;
  duration?: string;
  interactionCount?: number;
  description?: string;
  className?: string;
}

export const SliderCarousel = ({
  bannerMasterbrands = [],
  banners = [],
  customStyle,
  recipeTipsTitle,
  primarySubBrandColor,
  type = 'carousel',
  setDark = () => {},
  isForBanners = true,
  tips = [],
  theme,
}: ISliderCarousel & ISharedTemplateProps) => {
  const data = [...bannerMasterbrands, ...banners, ...tips];
  // TODO: select image lightness detection
  setDark(true);
  let bannerSizeFlag = 'Tall';
  for (let i = 0; i < data.length; i += 1) {
    if (data?.[i].bannerSize === 'Short') {
      bannerSizeFlag = 'Short';
    } else {
      bannerSizeFlag = 'Tall';
      break;
    }
  }

  let MainContainerStyle = '';
  let InnerContainerStyle = '';
  if (bannerSizeFlag === 'Tall') {
    if (isForBanners) {
      MainContainerStyle = `
      w-full
        ${
          type === 'carousel'
            ? `
            overflow-hidden
            max-h-heroCarouselTallMob
            h-75vh
            md:h-heroCarouselTallBannerHeight
            `
            : 'md:py-14 sm:py-5 border-0 bg-transparent'
        } ${customStyle}`;
      InnerContainerStyle = `${
        type === 'carousel'
          ? `
          max-h-heroCarouselInnerTallMob
          h-70vh
          md:h-heroCarouselInnerTallBannerHeight
          w-full
          overflow-hidden
          z-0
          `
          : ''
      }`;
    } else {
      MainContainerStyle = `w-full ${
        type === 'carousel'
          ? `
          md:rounded-b-HeroCurveBottom
          sm:rounded-b-heroBannerInner
          overflow-hidden
          bg-brown
          h-heroCarouselTallMob
          md:h-heroCarouselM
          lg:h-heroCarousel
        `
          : 'md:py-14 sm:py-5 border-0 bg-transparent'
      } ${customStyle}`;
      InnerContainerStyle = `${
        type === 'carousel'
          ? `
          h-heroCarouselInnerTallMob
          md:h-heroInnerM
          lg:h-heroInner
          w-full
          lg:rounded-b-heroCurve
          md:rounded-b-heroCurveM
          sm:rounded-b-heroInnerS
          overflow-hidden
          z-0`
          : ''
      }`;
    }
  } else {
    MainContainerStyle = `w-full ${
      type === 'carousel'
        ? `
          h-heroCarouselbanner
          overflow-hidden
          bg-brown
          `
        : `
          md:py-14
          sm:py-5
          border-0
          bg-transparent`
    } ${customStyle}`;
    InnerContainerStyle = `${
      type === 'carousel'
        ? `
          h-bannerShort
          md:h-heroInnerbanner
          w-full
          overflow-hidden
          z-0
        `
        : ''
    }`;
  }

  return (
    <div className={`${isForBanners ? 'curve-mask-top' : ''} w-full`}>
      <SliderWrapper
        data-test="slider-wrapper"
        $customColor={primarySubBrandColor || ''}
        className={`${isForBanners ? 'curve-mask-top' : ''} ${MainContainerStyle} ${
          theme ? 'bg-primary' : 'bg-brown'
        }`}
        type={type}
        $bannerSizeFlag={bannerSizeFlag}
      >
        <div {...(isForBanners && { className: 'curve-mask-bottom' })}>
          <DynamicSlider
            type={type}
            data={data}
            isForBanners={isForBanners}
            bannerSizeFlag={bannerSizeFlag}
            recipeTipsTitle={recipeTipsTitle}
            className={InnerContainerStyle}
          />
        </div>
      </SliderWrapper>
    </div>
  );
};
