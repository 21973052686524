import { NextLink } from 'components/NextLink';
import FadeInSection from '../FadeInSection';
import { Grid, GridItem, GridRow } from '../Grid';
import { LiquidButton } from '../LiquidButton';
import { ModalBox } from '../ModalBox';
import { Text as Paragraph, Title } from '../Typography';
import {
  BannerActions,
  BannerContent,
  BannerContentWrapper,
  BannerImage,
  BannerWrapper,
  Overlay,
  PlayButtonWrapper,
  PlayVideoWrapper,
  StyledButtonContainer,
  SuperText,
} from './styles';

import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { IImage } from 'components/HeroBanner/types';
import NextImage from 'components/NextImage';
import { LinksService } from 'service/links/linksService';
import { COLORS } from 'styles/constants';
import { MOBILE_MEDIA_BP } from '../../config/config';

interface ILinkProps {
  url?: string | undefined;
  displayName: string;
  openInNewTab: boolean;
  internalLink?: Record<string, string> | undefined;
  id: string;
}

export type BannerContentAlignment = 'Left' | 'Center' | 'Right';
export type BannerContentVerticalAlignment = 'Top' | 'Middle' | 'Bottom';
export type BannerSize = 'Tall' | 'Short';
export type ColorScheme = 'Black' | 'White' | 'Grey';

export interface IHeroBannerProps {
  alignment?: BannerContentAlignment;
  alignmentMobile?: BannerContentAlignment;
  verticalAlignment?: BannerContentVerticalAlignment;
  verticalAlignmentMobile?: BannerContentVerticalAlignment;
  title?: string;
  subTitle?: string;
  description?: string;
  textColor?: 'Black' | 'White';
  buttonColor?: 'Dark' | 'Light';
  superText?: string;
  link?: ILinkProps;
  image?: IImage;
  mobileImage?: Record<string, string>;
  video?: string;
  watchVideoLabel?: string;
  playColorScheme?: ColorScheme;
  bannerSize?: BannerSize;
  titleTag?: string;
  subTitleTag?: string;
  isRecipeBanner?: boolean;
  trackingEvents?: {
    clickHeroBannerCTA: () => void;
  };
  isOverlay?: boolean;
  priority?: boolean;
  thumbnailUrls?: string[];
  embedUrl?: string;
  uploadDate?: string;
  duration?: string;
  interactionCount?: number;
}

const LiquidButtonElement = ({
  text,
  width,
  color,
  textColor,
}: {
  text: string;
  width: number;
  color: string;
  textColor: string;
}) => <LiquidButton text={text} height={50} width={width} color={color} textColor={textColor} />;

export const HeroBannerMasterbrand = (
  {
    alignment,
    verticalAlignment,
    verticalAlignmentMobile,
    title,
    subTitle,
    description,
    textColor,
    buttonColor,
    link,
    image,
    mobileImage,
    video,
    watchVideoLabel,
    playColorScheme = 'Grey',
    bannerSize = 'Tall',
    superText,
    titleTag,
    subTitleTag,
    isRecipeBanner = false,
    trackingEvents,
    isOverlay = false,
    priority,
    thumbnailUrls,
    embedUrl,
    uploadDate,
    duration,
    interactionCount,
  }: IHeroBannerProps,
  { id = 'hero-banner' },
) => {
  const { pathTranslations } = useGlobalContext();
  let ColStart = 2;
  let ColSpan = 8;
  if (bannerSize === 'Short') {
    ColStart = 1;
    ColSpan = 7;
  }
  if (alignment === 'Center') {
    ColStart = 4;
    ColSpan = 10;
  } else if (alignment === 'Right') {
    ColStart = 10;
    ColSpan = 8;
  }

  const sourcesRecipes = [
    {
      srcSet: image?.desktopXXL,
      type: 'image/webp',
      media: '(min-width: 1920px)',
      width: image?.width,
      height: image?.height,
      mimeType: image?.mimeType,
    },
    {
      srcSet: image?.desktopXL,
      type: 'image/webp',
      media: '(min-width: 1680px)',
      width: image?.width,
      height: image?.height,
      mimeType: image?.mimeType,
    },
    {
      srcSet: image?.desktopL,
      type: 'image/webp',
      media: '(min-width: 1440px)',
      width: image?.width,
      height: image?.height,
      mimeType: image?.mimeType,
    },
    {
      srcSet: image?.desktop,
      type: 'image/webp',
      media: '(min-width: 768px)',
      width: image?.width,
      height: image?.height,
      mimeType: image?.mimeType,
    },
    {
      srcSet: image?.mobile,
      type: 'image/webp',
      media: MOBILE_MEDIA_BP,
      width: image?.width,
      height: image?.height,
      mimeType: image?.mimeType,
    },
  ];

  const sources = [
    {
      srcSet: image?.desktop,
      type: 'image/webp',
      media: '(min-width: 768px)',
      width: image?.width,
      height: image?.height,
      mimeType: image?.mimeType,
    },
    {
      srcSet: mobileImage && image && !isRecipeBanner ? mobileImage.mobile : image?.mobile,
      type: 'image/webp',
      media: MOBILE_MEDIA_BP,
      width: image?.width,
      height: image?.height,
      mimeType: image?.mimeType,
    },
  ];

  const LinkUrl = link
    ? LinksService.getInternalLinks({ item: link, pathTranslations, isHeroBanner: true })
    : null;
  const handleCTAClick = () => {
    if (trackingEvents?.clickHeroBannerCTA) {
      trackingEvents?.clickHeroBannerCTA();
    }
  };
  return (
    <BannerWrapper
      data-test={[`${id}-wrapper`]}
      $size={bannerSize}
      $verticalAlignment={verticalAlignment}
      $verticalAlignmentMobile={verticalAlignmentMobile}
      $color={textColor}
    >
      {isOverlay ? <Overlay /> : null}
      <Grid>
        <GridRow columns={16}>
          <GridItem colStart={ColStart} colSpan={ColSpan}>
            <BannerContentWrapper data-test={[`${id}-content-wrapper`]} $alignment={alignment}>
              <BannerContent data-test={[`${id}-content`]} $alignment={alignment}>
                {superText && (
                  <FadeInSection delay="300ms">
                    <SuperText
                      tag="p"
                      type="lead"
                      data-test={`${id}-super-text`}
                      $color={textColor}
                    >
                      {superText}
                    </SuperText>
                  </FadeInSection>
                )}
                {title && (
                  <FadeInSection delay="600ms">
                    <Title data-test={[`${id}-title`]} tag={titleTag || 'div'} type="xl">
                      {title}
                    </Title>
                  </FadeInSection>
                )}
                {subTitle && (
                  <FadeInSection delay="600ms">
                    <Title
                      data-test={[`${id}-subtitle`]}
                      tag={subTitleTag || 'div'}
                      type="august"
                      className="relative md:-top-4"
                    >
                      {subTitle}
                    </Title>
                  </FadeInSection>
                )}
                {description && (
                  <FadeInSection delay="900ms" $componentSize={bannerSize}>
                    <Paragraph data-test={[`${id}-text`]} tag="p" type="lg">
                      {description}
                    </Paragraph>
                  </FadeInSection>
                )}
                {LinkUrl || link?.url ? (
                  <StyledButtonContainer data-test={[`${id}-CTA-button`]} $alignment={alignment}>
                    <NextLink href={LinkUrl || link?.url} onClick={handleCTAClick}>
                      <span className="sr-only">{link?.displayName}</span>
                      <>
                        <LiquidButtonElement
                          text={link?.displayName || ''}
                          width={250}
                          color={buttonColor === 'Light' ? COLORS.secondary : COLORS.primary}
                          textColor={buttonColor === 'Light' ? COLORS.black : COLORS.white}
                        />
                      </>
                    </NextLink>
                  </StyledButtonContainer>
                ) : (
                  ''
                )}
                <BannerActions data-test={[`${id}-actions`]} $alignment={alignment}>
                  {video && (
                    <PlayVideoWrapper
                      data-test={[`${id}-play-video-wrapper`]}
                      $textColor={playColorScheme}
                    >
                      <PlayButtonWrapper data-test={[`${id}-play-button-wrapper`]}>
                        <ModalBox
                          videoID={video}
                          colorScheme={playColorScheme}
                          data-test={[`${id}-modal`]}
                          description={description || ''}
                          thumbnailUrls={thumbnailUrls ?? []}
                          embedUrl={embedUrl || ''}
                          uploadDate={uploadDate || ''}
                          duration={duration || ''}
                          interactionCount={interactionCount || 0}
                        />
                      </PlayButtonWrapper>{' '}
                      <Paragraph data-test={[`${id}-video-text`]} tag="div" type="sm">
                        {watchVideoLabel}
                      </Paragraph>
                    </PlayVideoWrapper>
                  )}
                </BannerActions>
              </BannerContent>
            </BannerContentWrapper>
          </GridItem>
        </GridRow>
      </Grid>
      <div className="w-full" data-test={[`${id}-image-wrapper`]}>
        <BannerImage data-test={[`${id}-image-${bannerSize}`]} $size={bannerSize}>
          {isRecipeBanner ? (
            <NextImage
              sources={sourcesRecipes}
              alt="Recipes hero"
              quality={75}
              priority={priority}
              preferredWidth={1920}
            />
          ) : (
            <NextImage
              sources={sources}
              alt="Hero image"
              quality={75}
              priority={priority}
              preferredWidth={1920}
            />
          )}
        </BannerImage>
      </div>
    </BannerWrapper>
  );
};
